(() => {
    const $menu = document.querySelector('.site-menu-modal__menu');
    const $subMenuDropdownWraps = document.querySelectorAll('.site-menu-modal__menu .menu-item-has-children');
    const $subMenuWraps = document.querySelectorAll('.site-menu-modal .menu-item-has-children .menu-item-has-children');

    const getSiblings = e => {
        // for collecting siblings
        let siblings = []; 
        // if no parent, return no sibling
        if(!e.parentNode) {
            return siblings;
        }
        // first child of the parent node
        let sibling  = e.parentNode.firstChild;
        
        // collecting siblings
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== e) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling;
        }
        return siblings;
    };

    const enableSubMenu = ($subMenuWrap, $subMenuToggle) => {
        if ($subMenuWrap.classList.contains('menu-item--active')) return;

        const $siblings = getSiblings($subMenuWrap);

        if ($siblings) {
            $siblings.forEach($sibling => {
                if ($sibling.id === $subMenuWrap.id) return;

                disableSubMenu($sibling, $subMenuToggle);
            });
        }

        $menu.scrollTo(0, 0);

        $subMenuWrap.classList.add('menu-item--active');

        $subMenuToggle.setAttribute('aria-expanded', 'true');
    };

    const disableSubMenu = ($subMenuWrap, $subMenuToggle) => {
        $subMenuWrap.classList.remove('menu-item--active');

        $subMenuToggle.setAttribute('aria-expanded', 'false');
    };

    const initSubMenuDropdowns = () => {
        $subMenuDropdownWraps.forEach($subMenuWrap => {
            if (!$subMenuWraps) return;

            const $subMenuToggle = $subMenuWrap.querySelector('a');

            if (!$subMenuToggle) return;

            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();
                
                if ($subMenuWrap.classList.contains('menu-item--active')) {
                    disableSubMenu($subMenuWrap, $subMenuToggle);
                } else {
                    enableSubMenu($subMenuWrap, $subMenuToggle);
                }
            });
        });
    };

    const initCloseButtons = () => {
        $subMenuDropdownWraps.forEach($subMenuWrap => {
            if (!$subMenuWraps) return;

            const $subMenuToggle = $subMenuWrap.querySelector('.menu-item--back');

            if (!$subMenuToggle) return;

            $subMenuToggle.addEventListener('click', e => {
                e.preventDefault();
                
                disableSubMenu($subMenuWrap, $subMenuToggle);
            });
        });
    };

    initSubMenuDropdowns();
    initCloseButtons();
})();
