import axios from 'axios';

export const updateSiteHeaderWoocommerceCartCounters = () => {
    const $siteHeaderWoocommerceCartCounters = document.querySelectorAll('.site-header-woocommerce-cart-counter');

    if (!$siteHeaderWoocommerceCartCounters) return;

    axios.get('/wp-json/wc/store/cart/', {
        headers: {
            'Cache-Control': 'no-cache',
        }
    }).then(response => {
        $siteHeaderWoocommerceCartCounters.forEach($siteHeaderWoocommerceCartCounter => {
            $siteHeaderWoocommerceCartCounter.innerHTML = response?.data?.items_count ?? 0;
        });
    });
};

(() => {
    updateSiteHeaderWoocommerceCartCounters();
})();
